<template>
  <div>
    <base-table
      :render-list-to-show="listToShow"
      :per-page="50"
      :wrapper-filters-data="wrapperFiltersData"
      :columns="columns"
      table-title="Заведения"
      :add-modal-input-data="addModalInputData"
      :update-modal-input-data="updateModalInputData"
    >
      <template v-slot:base-table-actions="props">
        <div class="topping__btns fx col-12">
          <button class="btn-t btn--borderColor flex-center" @click="props.toggleShowModalAdd">Добавить</button>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <template v-if="props.column.field === 'delete'">
          <button type="button" class="btn btn-danger" @click="props.deleteById(props.row['id'])">Удалить
          </button>
        </template>
        <template v-else-if="props.column.field === 'is_active'">
          <button v-if="props.row['is_active']" class="btn btn-success w-90"
                  @click="props.changeItemWithLoader(props.row['id'], {is_active: false})">ДА
          </button>
          <button v-else class="btn btn-danger w-90"
                  @click="props.changeItemWithLoader(props.row['id'], {is_active: true})">НЕТ
          </button>
        </template>
        <div v-else @click="toggleShowModalUpdate(props)" style="cursor: pointer">
          <template v-if="props.column.field === 'img_path'">
            <img :src="props.formattedRow[props.column.field] + '?v=' + props.row.updated_at" :alt="props.formattedRow['title']" width="100" height="100">
          </template>
          <template v-else-if="props.column.field === 'img_full_path'">
            <img v-if="props.row.img_full" :src="props.row.img_full.img_path + '?v=' + props.row.img_full.updated_at" :alt="props.formattedRow['title']" width="120" height="40">
          </template>
          <template v-else-if="props.column.field === 'banner_mobile_path'">
            <img v-if="props.row.banner_mobile" :src="props.row.banner_mobile.img_path + '?v=' + props.row.banner_mobile.updated_at" :alt="props.formattedRow['title']" width="100" height="100">
          </template>
          <template v-else-if="props.column.field === 'owner'">
            {{ props.formattedRow[props.column.field]['email'] + ' (' + props.formattedRow[props.column.field]['id'] + ')' }}
          </template>
          <template v-else-if="props.column.field === 'city'">
            {{ props.formattedRow[props.column.field] ? props.formattedRow[props.column.field]['title'] + ' (' + props.formattedRow[props.column.field]['id'] + ')' : ''}}
          </template>
          <template v-else-if="props.column.field === 'tinkoff_shop_code'">
            <span :class="props.formattedRow[props.column.field] ? 'text-success' : 'text-danger'">{{ props.formattedRow[props.column.field] ? 'ДА' : 'НЕТ' }}</span>
          </template>
          <template v-else-if="props.column.field === 'frontpad_secret'">
            <span :class="props.formattedRow[props.column.field] ? 'text-success' : 'text-danger'">{{ props.formattedRow[props.column.field] ? 'ДА' : 'НЕТ' }}</span>
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import Modal from '../../../components/table/modal.vue';
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/table/baseTable";
import ModalUpdate from "../../../components/table/modalUpdate.vue";

export default {
  name: "restaurantsList",
  components: {
    ModalUpdate,
    Multiselect,
    BaseAdminMixin,
    Modal,
    BaseTable
  },
  mixins: [
    BaseAdminMixin,
  ],
  props:[
    'user'
  ],
  data: function () {
    return {
      showModalAdd: false,
      showModalUpdate: false,
      restaurantId: null,
      selectedId: '',
      columns: [
        {
          label: '',
          field: 'img_path',
          type: 'number',
          width: '10%',
          sortable: false,
        },
        {
          label: 'Картинка full',
          field: 'img_full_path',
          type: 'number',
          width: '10%',
          sortable: false,
        },
        {
          label: 'Картинка mob',
          field: 'banner_mobile_path',
          type: 'number',
          width: '10%',
          sortable: false,
        },
        {
          label: 'ID',
          field: 'id',
          type: 'number',
          width: '5%',
          sortable: true,
        },
        {
          label: 'Title',
          field: 'title',
          width: '13%',
          sortable: true,
        },
        {
          label: 'Slug',
          field: 'slug',
          width: '8%',
          sortable: true,
        },
        {
          label: 'Владелец',
          field: 'owner',
          width: '15%',
          sortable: true,
        },
        // {
        //   label: 'Город',
        //   field: 'city',
        //   width: '15%',
        //   sortable: true,
        // },
        {
          label: 'Frontpad',
          field: 'frontpad_secret',
          width: '7%',
          sortable: true,
        },
        {
          label: 'Активность',
          field: 'is_active',
          width: '9%',
          sortable: true,
        },
        {
          label: 'Зарегистрирован в T-Bank',
          field: 'tinkoff_shop_code',
          sortable: true,
        },
        // {
        //   label: 'Удалить',
        //   field: 'delete',
        //   type: 'button',
        //   sortable: false,
        // },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        let img = null;
        let bannerMobile = null;
        if (elem.images) {
          for (let image of elem.images) {
            if (image.key == 'img_full') {
              img = image;
            } else if (image.key == 'banner_mobile') {
              bannerMobile = image
            }
          }
        }

        data.push({
          id: elem['id'],
          title: elem['title'],
          slug: elem['slug'],
          img_path: elem['img_path'],
          img_full: img,
          img_full_path: elem['img_full_path'],
          banner_mobile: bannerMobile,
          banner_mobile_path: elem['banner_mobile_path'],
          is_active: elem['is_active'],
          owner: elem['owner'],
          city: elem['city'],
          additional_param: elem['additional_param'],
          order_payment_types: elem['order_payment_types'],
          order_delivery_types: elem['order_delivery_types'],
          credential: elem['credential'],
          tinkoff_shop_code: elem['credential'] ? !!elem['credential']['tinkoff_shop_code'] : false ,
          frontpad_secret: elem['credential'] ? !!elem['credential']['frontpad_secret'] : false ,
          working_hours: elem['working_hours'],
          updated_at: elem['updated_at']
        });
      }
      return data;
    },
    toggleShowModalUpdate(props) {
      let elem = props.row
      if (elem) {
        this.selectedId = elem.id
        this.updateModalInputData.items.title.defaultValue = elem.title
        this.updateModalInputData.items.slug.defaultValue = elem.slug
        this.updateModalInputData.items.is_active.defaultValue = elem.is_active
        this.updateModalInputData.items.owner.defaultValue = elem.owner['id']
        this.updateModalInputData.items.city.defaultValue = elem.city ? elem.city['id'] : null

        this.updateModalInputData.items.order_payment_types.defaultValue = elem.order_payment_types.map(function (elem) {
          return elem.order_payment_type
        })
        this.updateModalInputData.items.order_payment_types.setDefaultInFilters = elem.order_payment_types.map(function (e) { return e.order_payment_type.id })

        this.updateModalInputData.items.order_delivery_types.defaultValue = elem.order_delivery_types.map(function (elem) {
          return elem.order_delivery_type
        })
        this.updateModalInputData.items.order_delivery_types.setDefaultInFilters = elem.order_delivery_types.map(function (e) { return e.order_delivery_type.id })

        this.updateModalInputData.items.description.defaultValue = elem.additional_param ? elem.additional_param['description'] : null
        this.updateModalInputData.items.short_description.defaultValue = elem.additional_param ? elem.additional_param['short_description'] : null
        this.updateModalInputData.items.time_to_cook.defaultValue = elem.additional_param ? elem.additional_param['time_to_cook'] : null
        this.updateModalInputData.items.time_to_deliver.defaultValue = elem.additional_param ? elem.additional_param['time_to_deliver'] : null
        this.updateModalInputData.items.avg_check.defaultValue = elem.additional_param ? elem.additional_param['avg_check'] : null
        this.updateModalInputData.items.term_delivery.defaultValue = elem.additional_param ? elem.additional_param['term_delivery'] : null
        this.updateModalInputData.items.address.defaultValue = elem.additional_param ? elem.additional_param['address'] : null
        this.updateModalInputData.items.requisites.defaultValue = elem.additional_param ? elem.additional_param['requisites'] : null
        this.updateModalInputData.items.seo_keys.defaultValue = elem.additional_param ? elem.additional_param['seo_keys'] : null
        this.updateModalInputData.items.seo_title.defaultValue = elem.additional_param ? elem.additional_param['seo_title'] : null
        this.updateModalInputData.items.seo_description.defaultValue = elem.additional_param ? elem.additional_param['seo_description'] : null
        this.updateModalInputData.items.min_order_sum.defaultValue = elem.additional_param ? elem.additional_param['min_order_sum'] : null
        this.updateModalInputData.items.tg_chat_id.defaultValue = elem.additional_param ? elem.additional_param['tg_chat_id'] : null
        this.updateModalInputData.items.phone.defaultValue = elem.additional_param ? elem.additional_param['phone'] : null

        this.updateModalInputData.items.frontpad_secret.defaultValue = elem.credential ? elem.credential['frontpad_secret'] : null

        this.updateModalInputData.items.time_opening_0.defaultValue = elem.working_hours && elem.working_hours[0] ? elem.working_hours[0]['opening_time'] : null
        this.updateModalInputData.items.time_closing_0.defaultValue = elem.working_hours && elem.working_hours[0] ? elem.working_hours[0]['closing_time'] : null
        this.updateModalInputData.items.time_opening_1.defaultValue = elem.working_hours && elem.working_hours[1] ? elem.working_hours[1]['opening_time'] : null
        this.updateModalInputData.items.time_closing_1.defaultValue = elem.working_hours && elem.working_hours[1] ? elem.working_hours[1]['closing_time'] : null
        this.updateModalInputData.items.time_opening_2.defaultValue = elem.working_hours && elem.working_hours[2] ? elem.working_hours[2]['opening_time'] : null
        this.updateModalInputData.items.time_closing_2.defaultValue = elem.working_hours && elem.working_hours[2] ? elem.working_hours[2]['closing_time'] : null
        this.updateModalInputData.items.time_opening_3.defaultValue = elem.working_hours && elem.working_hours[3] ? elem.working_hours[3]['opening_time'] : null
        this.updateModalInputData.items.time_closing_3.defaultValue = elem.working_hours && elem.working_hours[3] ? elem.working_hours[3]['closing_time'] : null
        this.updateModalInputData.items.time_opening_4.defaultValue = elem.working_hours && elem.working_hours[4] ? elem.working_hours[4]['opening_time'] : null
        this.updateModalInputData.items.time_closing_4.defaultValue = elem.working_hours && elem.working_hours[4] ? elem.working_hours[4]['closing_time'] : null
        this.updateModalInputData.items.time_opening_5.defaultValue = elem.working_hours && elem.working_hours[5] ? elem.working_hours[5]['opening_time'] : null
        this.updateModalInputData.items.time_closing_5.defaultValue = elem.working_hours && elem.working_hours[5] ? elem.working_hours[5]['closing_time'] : null
        this.updateModalInputData.items.time_opening_6.defaultValue = elem.working_hours && elem.working_hours[6] ? elem.working_hours[6]['opening_time'] : null
        this.updateModalInputData.items.time_closing_6.defaultValue = elem.working_hours && elem.working_hours[6] ? elem.working_hours[6]['closing_time'] : null
      }

      props.toggleShowModalUpdate(props.row);
    },
    updateList() {
      this.$root.$emit('update-list')
    }
  },
  computed: {
    wrapperFiltersData() {
      return {
        title: "Заведения",
        filtersUrl: "/restaurant/getList",
        filtersUrlMethod: "get",
        startWithRequest: true,
        items: {
          owners: {
            enable: true,
            multiple: true,
            getDataLink: '/users/getOwners',
            placeholder: 'Владельцы',
            specifyKeyForLabel: 'email',
            viewLabel: 'titleWithId',
            column: 1
          },
          cities: {
            enable: true,
            multiple: true,
            getDataLink: '/city/getList',
            placeholder: 'Города',
            specifyKeyForLabel: 'title',
            viewLabel: 'titleWithId',
            column: 1
          },
          title: {
            enable: true,
            simpleInputType: 'text',
            placeholder: 'Название',
            selectedKey: 'title',
            column: 2
          },
          onlyDisabled: {
            enable: true,
            filterKey: 'onlyDisabled',
            upperSpanText: 'Только не активные: ',
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            placeholder: 'disabled',
            setDefaultInFilters: false,
            bottomSpanClass: 'slider round',
            selectedKey: 'onlyDisabled',
            column: 3
          },
        },
      }
    },
    updateModalInputData() {
      return {
        updateModalTitle: 'Заведение ' + this.selectedId,
        updateModalUrl: '/restaurant/update',
        updateModalMethod: 'post',
        updateModalPrimaryKey: 'id',
        items:{
          owner: {
            multiple: false,
            allowEmpty: false,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/users/getOwners',
            specifyKeyForLabel: 'email',
            upperSpanText: 'Владелец*',
            viewLabel: 'titleWithId',
            showLabels: true,
            selectedKey: 'owner_id',
          },
          city: {
            multiple: false,
            allowEmpty: false,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/city/getList',
            specifyKeyForLabel: 'title',
            upperSpanText: 'Город',
            viewLabel: 'titleWithId',
            showLabels: true,
            selectedKey: 'city_id',
          },
          order_payment_types: {
            multiple: true,
            getDataLink: '/order/getOrderPaymentTypes',
            specifyKeyForLabel: 'description',
            upperSpanText: 'Доступные типы оплаты',
            viewLabel: 'titleWithId',
            selectedKey: 'order_payment_types_ids',
          },
          order_delivery_types: {
            multiple: true,
            getDataLink: '/order/getOrderDeliveryTypes',
            specifyKeyForLabel: 'description',
            upperSpanText: 'Доступные типы доставки',
            viewLabel: 'titleWithId',
            selectedKey: 'order_delivery_types_ids',
          },
          title: {
            simpleInputType: 'text',
            upperSpanText: 'Title*',
            selectedKey: 'title',
          },
          slug: {
            simpleInputType: 'text',
            upperSpanText: 'Slug*',
            selectedKey: 'slug',
          },
          is_active: {
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            upperSpanText: 'Активность',
            placeholder: 'disabled',
            bottomSpanClass: 'slider round',
            selectedKey: 'is_active',
          },
          img: {
            upperSpanText: 'Картинка',
            simpleInputType: 'file',
            placeholder: 'Img',
            selectedKey: 'img',
          },
          img_full: {
            upperSpanText: 'Картинка детальная*',
            simpleInputType: 'file',
            placeholder: 'Картинка детальная',
            selectedKey: 'img_full',
          },
          banner_mobile: {
            upperSpanText: 'Картинка мобильная*',
            simpleInputType: 'file',
            placeholder: 'Картинка мобильная',
            selectedKey: 'banner_mobile',
          },
          min_order_sum: {
            simpleInputType: 'number',
            upperSpanText: 'Мин. сумма заказа',
            selectedKey: 'min_order_sum',
          },
          phone: {
            simpleInputType: 'number',
            upperSpanText: 'Телефон для связи',
            selectedKey: 'phone',
          },
          address: {
            simpleInputType: 'text',
            upperSpanText: 'Адрес',
            selectedKey: 'address',
          },
          requisites: {
            simpleInputType: 'textarea',
            upperSpanText: 'Реквизиты',
            selectedKey: 'requisites',
          },
          description: {
            simpleInputType: 'textarea',
            upperSpanText: 'Описание',
            selectedKey: 'description',
          },
          short_description: {
            simpleInputType: 'text',
            upperSpanText: 'Короткое описание',
            selectedKey: 'short_description',
          },
          tg_chat_id: {
            simpleInputType: 'text',
            upperSpanText: 'Телеграм chat_id',
            selectedKey: 'tg_chat_id',
          },
          frontpad_secret: {
            simpleInputType: 'text',
            upperSpanText: 'Frontpad secret',
            selectedKey: 'frontpad_secret',
          },
          time_to_cook: {
            simpleInputType: 'text',
            upperSpanText: 'Время готовки (через тире в минутах)',
            selectedKey: 'time_to_cook',
          },
          time_to_deliver: {
            simpleInputType: 'text',
            upperSpanText: 'Время доставки (через тире в минутах)',
            selectedKey: 'time_to_deliver',
          },
          avg_check: {
            simpleInputType: 'number',
            upperSpanText: 'Средний чек',
            selectedKey: 'avg_check',
          },
          term_delivery: {
            simpleInputType: 'textarea',
            upperSpanText: 'Описание доставки',
            selectedKey: 'term_delivery',
          },
          seo_keys: {
            simpleInputType: 'text',
            upperSpanText: 'SEO keys',
            selectedKey: 'seo_keys',
          },
          seo_title: {
            simpleInputType: 'text',
            upperSpanText: 'SEO title',
            selectedKey: 'seo_title',
          },
          seo_description: {
            simpleInputType: 'textarea',
            upperSpanText: 'SEO description',
            selectedKey: 'seo_description',
          },
          time_opening_1: {
            simpleInputType: 'time',
            upperSpanText: 'Время открытия в ПН',
            selectedKey: 'time_opening_1',
          },
          time_closing_1: {
            simpleInputType: 'time',
            upperSpanText: 'Время закрытия в ПН',
            selectedKey: 'time_closing_1',
          },
          time_opening_2: {
            simpleInputType: 'time',
            upperSpanText: 'Время открытия в ВТ',
            selectedKey: 'time_opening_2',
          },
          time_closing_2: {
            simpleInputType: 'time',
            upperSpanText: 'Время закрытия в ВТ',
            selectedKey: 'time_closing_2',
          },
          time_opening_3: {
            simpleInputType: 'time',
            upperSpanText: 'Время открытия в СР',
            selectedKey: 'time_opening_3',
          },
          time_closing_3: {
            simpleInputType: 'time',
            upperSpanText: 'Время закрытия в СР',
            selectedKey: 'time_closing_3',
          },
          time_opening_4: {
            simpleInputType: 'time',
            upperSpanText: 'Время открытия в ЧТ',
            selectedKey: 'time_opening_4',
          },
          time_closing_4: {
            simpleInputType: 'time',
            upperSpanText: 'Время закрытия в ЧТ',
            selectedKey: 'time_closing_4',
          },
          time_opening_5: {
            simpleInputType: 'time',
            upperSpanText: 'Время открытия в ПТ',
            selectedKey: 'time_opening_5',
          },
          time_closing_5: {
            simpleInputType: 'time',
            upperSpanText: 'Время закрытия в ПТ',
            selectedKey: 'time_closing_5',
          },
          time_opening_6: {
            simpleInputType: 'time',
            upperSpanText: 'Время открытия в СБ',
            selectedKey: 'time_opening_6',
          },
          time_closing_6: {
            simpleInputType: 'time',
            upperSpanText: 'Время закрытия в СБ',
            selectedKey: 'time_closing_6',
          },
          time_opening_0: {
            simpleInputType: 'time',
            upperSpanText: 'Время открытия в ВС',
            selectedKey: 'time_opening_0',
          },
          time_closing_0: {
            simpleInputType: 'time',
            upperSpanText: 'Время закрытия в ВС',
            selectedKey: 'time_closing_0',
          },
        }
      }
    },
    addModalInputData() {
      return {
        addModalTitle: 'Добавить',
        addModalUrl: '/restaurant/create',
        addModalMethod: 'post',
        items:{
          owners: {
            multiple: false,
            allowEmpty: false,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/users/getOwners',
            placeholder: 'Выберите владельца*',
            specifyKeyForLabel: 'email',
            viewLabel: 'titleWithId',
            selectedKey: 'owner_id',
          },
          city: {
            multiple: false,
            allowEmpty: false,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/city/getList',
            placeholder: 'Выберите город',
            specifyKeyForLabel: 'title',
            viewLabel: 'titleWithId',
            selectedKey: 'city_id',
          },
          order_payment_types: {
            multiple: true,
            allowEmpty: true,
            getDataLink: '/order/getOrderPaymentTypes',
            specifyKeyForLabel: 'description',
            placeholder: 'Выберите доступные типы оплаты',
            viewLabel: 'titleWithId',
            selectedKey: 'order_payment_types_ids',
          },
          order_delivery_types: {
            multiple: true,
            allowEmpty: true,
            getDataLink: '/order/getOrderDeliveryTypes',
            specifyKeyForLabel: 'description',
            placeholder: 'Доступные типы доставки',
            viewLabel: 'titleWithId',
            selectedKey: 'order_delivery_types_ids',
          },
          title: {
            simpleInputType: 'text',
            placeholder: 'title*',
            selectedKey: 'title',
          },
          slug: {
            simpleInputType: 'text',
            placeholder: 'slug*',
            selectedKey: 'slug',
          },
          // is_active: {
          //   simpleInputType: 'checkbox',
          //   simpleInputTypeClass: 'switch',
          //   upperSpanText: 'Активность',
          //   placeholder: 'disabled',
          //   setDefaultInFilters: false,
          //   bottomSpanClass: 'slider round',
          //   selectedKey: 'is_active',
          // },
          img: {
            upperSpanText: 'Картинка*',
            simpleInputType: 'file',
            placeholder: 'Картинка',
            selectedKey: 'img',
          },
          img_full: {
            upperSpanText: 'Картинка детальная*',
            simpleInputType: 'file',
            placeholder: 'Картинка детальная',
            selectedKey: 'img_full',
          },
          banner_mobile: {
            upperSpanText: 'Картинка мобильная*',
            simpleInputType: 'file',
            placeholder: 'Картинка мобильная',
            selectedKey: 'banner_mobile',
          },
          min_order_sum: {
            simpleInputType: 'number',
            placeholder: 'Мин. сумма заказа',
            selectedKey: 'min_order_sum',
          },
          phone: {
            simpleInputType: 'number',
            placeholder: 'Телефон для связи',
            selectedKey: 'phone',
          },
          address: {
            simpleInputType: 'text',
            placeholder: 'Адрес',
            selectedKey: 'address',
          },
          requisites: {
            simpleInputType: 'textarea',
            placeholder: 'Реквизиты',
            selectedKey: 'requisites',
          },
          description: {
            simpleInputType: 'textarea',
            placeholder: 'Описание',
            selectedKey: 'description',
          },
          short_description: {
            simpleInputType: 'text',
            placeholder: 'Короткое описание',
            selectedKey: 'short_description',
          },
          tg_chat_id: {
            simpleInputType: 'text',
            placeholder: 'Телеграм chat_id',
            selectedKey: 'tg_chat_id',
          },
          frontpad_secret: {
            simpleInputType: 'text',
            placeholder: 'Frontpad secret',
            selectedKey: 'frontpad_secret',
          },
          time_to_cook: {
            simpleInputType: 'text',
            placeholder: 'Время готовки (через тире в минутах)',
            selectedKey: 'time_to_cook',
          },
          time_to_deliver: {
            simpleInputType: 'text',
            placeholder: 'Время доставки (через тире в минутах)',
            selectedKey: 'time_to_deliver',
          },
          avg_check: {
            simpleInputType: 'number',
            placeholder: 'Средний чек',
            selectedKey: 'avg_check',
          },
          term_delivery: {
            simpleInputType: 'textarea',
            placeholder: 'Описание доставки',
            selectedKey: 'term_delivery',
          },
          seo_keys: {
            simpleInputType: 'text',
            placeholder: 'SEO keys',
            selectedKey: 'seo_keys',
          },
          seo_title: {
            simpleInputType: 'text',
            placeholder: 'SEO title',
            selectedKey: 'seo_title',
          },
          seo_description: {
            simpleInputType: 'textarea',
            placeholder: 'SEO description',
            selectedKey: 'seo_description',
          },
          time_opening_1: {
            simpleInputType: 'time',
            upperSpanText: 'Время открытия в ПН',
            selectedKey: 'time_opening_1',
          },
          time_closing_1: {
            simpleInputType: 'time',
            upperSpanText: 'Время закрытия в ПН',
            selectedKey: 'time_closing_1',
          },
          time_opening_2: {
            simpleInputType: 'time',
            upperSpanText: 'Время открытия в ВТ',
            selectedKey: 'time_opening_2',
          },
          time_closing_2: {
            simpleInputType: 'time',
            upperSpanText: 'Время закрытия в ВТ',
            selectedKey: 'time_closing_2',
          },
          time_opening_3: {
            simpleInputType: 'time',
            upperSpanText: 'Время открытия в СР',
            selectedKey: 'time_opening_3',
          },
          time_closing_3: {
            simpleInputType: 'time',
            upperSpanText: 'Время закрытия в СР',
            selectedKey: 'time_closing_3',
          },
          time_opening_4: {
            simpleInputType: 'time',
            upperSpanText: 'Время открытия в ЧТ',
            selectedKey: 'time_opening_4',
          },
          time_closing_4: {
            simpleInputType: 'time',
            upperSpanText: 'Время закрытия в ЧТ',
            selectedKey: 'time_closing_4',
          },
          time_opening_5: {
            simpleInputType: 'time',
            upperSpanText: 'Время открытия в ПТ',
            selectedKey: 'time_opening_5',
          },
          time_closing_5: {
            simpleInputType: 'time',
            upperSpanText: 'Время закрытия в ПТ',
            selectedKey: 'time_closing_5',
          },
          time_opening_6: {
            simpleInputType: 'time',
            upperSpanText: 'Время открытия в СБ',
            selectedKey: 'time_opening_6',
          },
          time_closing_6: {
            simpleInputType: 'time',
            upperSpanText: 'Время закрытия в СБ',
            selectedKey: 'time_closing_6',
          },
          time_opening_0: {
            simpleInputType: 'time',
            upperSpanText: 'Время открытия в ВС',
            selectedKey: 'time_opening_0',
          },
          time_closing_0: {
            simpleInputType: 'time',
            upperSpanText: 'Время закрытия в ВС',
            selectedKey: 'time_closing_0',
          },
        }
      }
    }
  },
}
</script>
