<template>
  <div>
    <modal-update
      v-if="enableModalUpdate"
      :update-modal-input-data="updateModalInputData"
      :primary-key-value="modalUpdateId"
      :primary-key="updateModalInputData.updateModalPrimaryKey"
      :title="updateModalInputData.updateModalTitle ? updateModalInputData.updateModalTitle : ''"
      @onUpdate="updateList"
      :update-modal-url="updateModalUrl"
      ref="modalUpdateComponent"
    ></modal-update>
    <modal-add v-if="enableModalAdd"
               :add-modal-input-data="addModalInputData"
               :add-modal-url="addModalUrl"
               @insert-added-data="pasteNewAddModalItems"
               ref="modalComponent"
    ></modal-add>
    <wrapper-filters-component
        ref="wrapperFilters"
        v-if="enabledFilters"
        :title="tableTitle"
        :filtersUrl="filtersUrl"
        :filtersUrlMethod="wrapperFiltersData.filtersUrlMethod ?? 'get'"
        :startWithRequest="filtersStartWithRequest"
        :sendFilterRequestAfterSelect="sendFilterRequestAfterSelect"
        :setEnabledFilters="filtersItems"
        :columns="columns"
        :enable-save-csv="enableSaveCsv"
        :save-csv-url="saveCsvUrl"
        :show-collapse-filter="wrapperFiltersData.showCollapseFilter"
        :show-reset-filter-button="wrapperFiltersData.showResetFilterButton"
        :wrapper-load-color="wrapperFiltersData.wrapperLoadColor"
        :show-alert-message="this.showAlertMessage"
        @set-filtered-list="setFilteredList"
        :accept-filters-button-label="acceptFiltersButtonLabel"
        show-filters-button-label="Show filters"
        minimize-filters-button-label="Minimize"
        reset-filters-button-label="Reset"
        :csv-filename="csvFilename"
        :download-csv-text="downloadCsvText"
    ></wrapper-filters-component>
    <vue-good-table
        :title="tableTitle"
        :columns="prepareColumns"
        :rows="baseTableListToShow"
        :lineNumbers="tableLineNumbers"
        @on-sort-change="updateDynamicSort"
        :sort-options="{
              enabled: tableSortOptionEnabled,
              initialSortBy: initialSortBy
            }"
        :style-class="'vgt-table admin-table'"
        :globalSearch="true"
        :pagination-options="{
              enabled: enablePagination,
              perPage: computedPerPage,
              rowsPerPageLabel: rowsPerPageText,
              perPageDropdownEnabled: !useCustomPagination,
              nextLabel: nextText,
              prevLabel: prevText,
              page: paginationData.currentPage
            }"
    >
      <template v-slot:table-actions>
        <slot name="base-table-actions"
              :changeAllWithLoader="changeAllWithLoader"
              :toggleShowModalAdd="toggleShowModalAdd"
              :deleteAll="deleteAll"
              :query="query"
        >
        </slot>
      </template>
      <template v-slot:table-row="props">
        <slot name="base-table-row"
              :changeItemWithLoader="changeItemWithLoader"
              :toggleShowModalUpdate="toggleShowModalUpdate"
              :deleteById="deleteById"
              :row="props.row"
              :column="props.column"
              :formattedRow="props.formattedRow">
          <div class="admin-table-element" style="font-size: 16px">
            <template v-if="props.column.field === 'is_active'">
              <button v-if="props.formattedRow[props.column.field]" class="btn btn-success w-100"
                      @click="changeItemWithLoader(props.row['id'], {is_active: false})">ON
              </button>
              <button v-else class="btn btn-danger w-100"
                      @click="changeItemWithLoader(props.row['id'], {is_active: true})">OFF
              </button>
            </template>
            <div v-else @click="updateModalInputData ? toggleShowModalUpdate(props.row) : null" style="cursor: pointer">
              <template>
                {{ props.formattedRow[props.column.field] }}
              </template>
            </div>
          </div>
        </slot>
      </template>
      <template v-slot:pagination-bottom="props" v-if="useCustomPagination && paginationData.isPaginationLoaded">
        <slot name="base-table-pagination-bottom">
          <custom-pagination
              v-if="useUserPagination"
              ref="customPagination"
              :total="paginationData.total"
              :pageChanged="$refs.wrapperFilters.updateByFilters"
              :perPageChanged="$refs.wrapperFilters.updateByFilters"
              :perPage="paginationData.perPage"
          >
          </custom-pagination>
          <custom-admin-pagination
              v-if="useAdminPagination"
              ref="customPagination"
              :total="paginationData.total"
              :pageChanged="$refs.wrapperFilters.updateByFilters"
              :perPageChanged="$refs.wrapperFilters.updateByFilters"
              :perPage="paginationData.perPage"
              :lastPage="paginationData.lastPage"
          >
          </custom-admin-pagination>
        </slot>
      </template>
      <template v-slot:table-actions-bottom>
        <slot name="base-table-actions-bottom">
        </slot>
      </template>
      <template v-slot:emptystate>
        <slot name="emptystate">
          {{emptyStateText}}
        </slot>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import CustomPagination from "./pagination/customPagination.vue";
import CustomAdminPagination from "./pagination/customAdminPagination.vue";
import BaseAdminMixin from "../mixins/BaseAdminMixin.vue";
import ModalAdd from './modalAdd.vue';
import WrapperFiltersComponent from "./wrapperFilters.vue";
import ModalUpdate from "./modalUpdate.vue";


export default {
  name: "baseTableComponent",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    ModalUpdate,
    CustomPagination,
    CustomAdminPagination,
    WrapperFiltersComponent,
    ModalAdd,
  },
  props: {
    columns: {
      type: Array,
      default: []
    },
    wrapperFiltersData: {
      type: Object,
      default: {}
    },
    addModalInputData: {
      type: Object,
      default: null,
    },
    updateListOnAdd: {
      type: Boolean,
      default: false,
    },
    updateModalInputData: {
      type: Object,
      default: null,
    },
    renderListToShow: {
      type: Function,
      required: true
    },
    tableLineNumbers: {
      type: Boolean,
      default: false
    },
    tableSortOptionEnabled: {
      type: Boolean,
      default: true
    },
    initialSortBy: {
      type: Object,
      default: null,
    },
    tableTitle: {
      type: String,
      default: 'Table title'
    },
    rowsPerPageText: {
      type: String,
      default: 'Rows per page'
    },
    nextText: {
      type: String,
      default: 'Next'
    },
    prevText: {
      type: String,
      default: 'Previous'
    },
    enablePagination: {
      type: Boolean,
      default: true
    },
    deleteAllUrl: {type: String},
    deleteAllMethod: {
      type: String,
      default: 'delete'
    },
    deleteByIdUrl: {type: String},
    deleteByIdMethod: {
      type: String,
      default: 'delete'
    },
    deleteByIdKey: {
      type: String,
      default: 'id'
    },
    deleteAdditionalParams: {
      type: Object,
      default: null,
    },
    changeAllWithLoaderMethod: {type: String},
    changeAllWithLoaderUrl: {type: String},
    saveCsvUrl: {
      type: String
    },
    enableSaveCsv: {
      type: Boolean,
      default: false
    },
    useCustomPagination: {
      type: Boolean,
      default: false
    },
    showAlertMessage: {
      type: Boolean,
      default: true
    },
    csvFilename: {
      type: String,
      default: 'phoneHistory'
    },
    acceptFiltersButtonLabel: {
      type: String,
      default: 'Apply filters'
    },
    downloadCsvText: {
      type: String,
      default: 'Download CSV'
    },
    emptyStateText: {
      type: String,
      default: 'No data for table'
    },
    dynamicSort: {
      type: Boolean,
      default: false
    },
    useAdminPagination: {
      type: Boolean,
      default: false
    },
    useUserPagination: {
      type: Boolean,
      default: false
    },
    perPageDefault: {
      type: Number,
      default: 25
    }
  },
  async created() {
    this.extractFiltersData();
    this.prepareColumnsSortFn();
    await this.prepareInputData();
  },

  data() {
    return {
      filteredList: [],
      prepareColumns: [],
      renderList: [],
      enableModalAdd: false,
      enableModalUpdate: false,
      enabledFilters: false,
      updateModalUrl: null,
      modalUpdateId: null,
      showModalUpdate: false,
      getListArr: [],
      addModalUrl: null,
      filterTitle: '',
      filtersUrl: '',
      filtersUrlMethod: 'get',
      filtersItems: {},
      filtersStartWithRequest: false,
      sendFilterRequestAfterSelect: false,
      paginationData: {
        total: 0,
        perPage: 300,
        currentPage: 0,
        lastPage: 0,
        isPaginationLoaded: false
      },
      changeItemWithLoaderMethod: null,
      changeItemWithLoaderUrl: null,
    }
  },
  mounted() {
    this.$root.$on('add-item', (data) => {
      this.pasteNewAddModalItems(data)
    })
    this.$root.$on('update-item', (data) => {
      this.updateFilteredListByIds(data)
    })
    this.$root.$on('update-list', (data) => {
      this.$refs.wrapperFilters.updateByFilters()
    })
    this.$root.$on('update-item-by-id',(valuesToUpdate,index) => {
      for (const key in valuesToUpdate) {
        if (valuesToUpdate.hasOwnProperty(key)) {
          this.filteredList[index][key] = valuesToUpdate[key];
        }
      }
    })
    if (this.updateModalInputData && this.updateModalInputData.updateModalUrl) {
      this.changeItemWithLoaderUrl = this.updateModalInputData.updateModalUrl;
    }

    if (this.updateModalInputData && this.updateModalInputData.updateModalMethod) {
      this.changeItemWithLoaderMethod = this.updateModalInputData.updateModalMethod;
    }
  },
  methods: {
    updateDynamicSort(sortData){
      if(this.dynamicSort){
        let sortBy = this.prepareOriginSortColumns(sortData)
        this.$refs.wrapperFilters.filters['sortItem'] = {}
        this.$refs.wrapperFilters.filters['sortItem'][sortBy.field] = sortBy.type
        this.$refs.wrapperFilters.updateByFilters();

        if(this.useCustomPagination){
          this.$refs.customPagination.customPageChange(1)
        }
      }
    },
    prepareOriginSortColumns(columns){
      let data = [];
      for(let column of this.columns){
        if(column?.sortable == true){
          if(column.field == columns[0].field){
              data.push({
                field: column.sortByColumn,
                type: columns[0].type
              })
          }
        }
      }
      return data[0];
    },
    async prepareInputData() {
      if (this.addModalInputData) {
        if (this.addModalInputData.addModalUrl) {
          this.addModalUrl = this.addModalInputData.addModalUrl;
        }
        if (this.addModalInputData.items) {
          await this.addGetListItems(this.addModalInputData.items);
          this.enableModalAdd = true
        }
      }

      if (this.updateModalInputData) {
        if (this.updateModalInputData.updateModalUrl) {
          this.updateModalUrl = this.updateModalInputData.updateModalUrl;
        }

        if (this.updateModalInputData.items) {
          await this.addGetListItems(this.updateModalInputData.items);
          this.enableModalUpdate = true
        }
      }

      if (this.wrapperFiltersData.sendFilterRequestAfterSelect) {
        this.sendFilterRequestAfterSelect = true;
      }
      if (this.wrapperFiltersData.items) {
        await this.addGetListItems(this.wrapperFiltersData.items);
        this.enabledFilters = true;
      } else if (this.wrapperFiltersData.startWithRequest) {
        this.enabledFilters = true;
      }
    },
    prepareColumnsSortFn() {
      this.prepareColumns = this.columns.map(el => {
        if (el.sortFn && typeof this[el.sortFn] === 'function') {
          el.sortFn = this[el.sortFn]
        }
        return el;
      });
    },
    async addGetListItems(items) {
      for (let item in items) {
        if (items[item].getDataLink !== undefined) {
          let getListArrItem = this.getListArr.find((el) => el.link === items[item].getDataLink);
          if (!getListArrItem) {
            let list = await this.sendRequest(items[item].getDataLink);
            let obj = {
              link: items[item].getDataLink,
              data: list
            }
            this.getListArr.push(obj);
            items[item]['data'] = list;
          } else {
            items[item]['data'] = getListArrItem.data;
          }
        }
      }
    },
    extractFiltersData() {
      this.filterTitle = this.wrapperFiltersData.title;
      this.filtersUrl = this.wrapperFiltersData.filtersUrl;
      this.filtersItems = this.wrapperFiltersData.items
      if (this.wrapperFiltersData.startWithRequest) {
        this.filtersStartWithRequest = this.wrapperFiltersData.startWithRequest
      }
    },
    pasteNewSingleItem(item) {
      this.filteredList.data.unshift(item);
    },
    setFilteredList(response) {
      this.filteredList = response;
      if(this.useCustomPagination){
        this.setPaginationOptions()
      }
    },
    setPaginationOptions() {
      this.paginationData.total = this.filteredList.total
      this.paginationData.perPage = this.filteredList.per_page
      this.paginationData.currentPage = this.filteredList.currentPage
      this.paginationData.lastPage = this.filteredList?.last_page
      this.paginationData.isPaginationLoaded = true
    },
    pasteNewAddModalItems(data) {
      if (!this.updateListOnAdd) {
        this.filteredList.unshift(...data);
      } else {
        this.updateList()
      }
    },
    toggleShowModalAdd() {
      if (this.$refs.modalComponent) {
        this.$refs.modalComponent.toggleShowModalAdd();
      }
    },
    toggleShowModalUpdate(elem) {
      this.modalUpdateId = elem[this.updateModalInputData.updateModalPrimaryKey ?? 'id']
      if (this.$refs.modalUpdateComponent) {
        this.$refs.modalUpdateComponent.toggleShowModalUpdate()
      }
    },
    async changeItemWithLoader(id, params) {
      let index = this.getIndexByProp(this.filteredList, 'id', id);
      let newObj = Object.assign({}, this.filteredList[index], params);
      await this.doWithStopRequest(() => this.doWithLoader(async () => {
        let response = await this.sendRequest(
            this.changeItemWithLoaderUrl,
            this.changeItemWithLoaderMethod,
            newObj
        );
        if (response) {
          await this.alertMessage(
            'Item has been changed',
            'info'
          );
          if (response['data']) {
            for (let key in response['data']) {
              this.filteredList[index][key] = response['data'][key];
            }
          } else {
            for (let key in params) {
              this.filteredList[index][key] = params[key];
            }
          }
        }
      }), 'changeItem')
    },
    async changeAllWithLoader(data) {
      await this.doWithStopRequest(() => this.doWithLoader(async () => {
        let filteredServiceVariationsIds = this.filteredList.map(function (elem) {
          return elem['id'];
        });

        if (confirm('Вы уверены что хотите поменять ' + filteredServiceVariationsIds.length + ' вариаций?')) {
          let params = data;
          params['ids'] = filteredServiceVariationsIds;
          let response = await this.sendRequest(
              this.changeAllWithLoaderUrl,
              this.changeAllWithLoaderMethod,
              params
          );

          if (response) {
            this.filteredList = this.filteredList.map(function (elem) {
              elem = Object.assign(elem, data);
              return elem;
            });
            await this.alertMessage(
                'Items have been changed',
                'info'
            );
          }
        }
      }), 'Изменение сервисов')
    },
    getIndexByProp(list, propKey, propValue) {
      for (let key in list) {
        if (list[key][propKey] == propValue) {
          return key;
        }
      }
      return null;
    },
    sortNumber(a, b) {
      let x = Number(a);
      let y = Number(b);
      return (x < y ? -1 : (x > y ? 1 : 0));
    },
    async deleteAll() {
      await this.doWithStopRequest(() => this.doWithLoader(async () => {
        let ids = this.filteredList.map(function (elem) {
          return elem['id'];
        });

        if (confirm('Вы уверены что хотите удалить ' + ids.length + ' вариаций?')) {
          let response = await this.sendRequest(
              this.deleteAllUrl,
              this.deleteAllMethod,
              {
                ids: ids
              }
          );

          if (response) {
            this.filteredList = [];
            await this.alertMessage(
                'Items have been deleted',
                'info'
            );
          }
        }
      }), 'Удаление')
    },
    async deleteById(id) {
      await this.doWithStopRequest(() => this.doWithLoader(async () => {
        const formData = new FormData();
        formData.append(this.deleteByIdKey, id);
        if (this.deleteAdditionalParams) {
            for (let key in this.deleteAdditionalParams) {
              formData.append(key, this.deleteAdditionalParams[key]);
            }
        }
        let response = await this.sendRequest(
            this.deleteByIdUrl,
            this.deleteByIdMethod,
            formData,
          {'Content-Type': 'multipart/form-data'}
        );
        if (response) {
          if (this.filteredList.hasOwnProperty('data')) {
            let index = this.getIndexByProp(this.filteredList['data'], 'id', id);
            this.filteredList['data'].splice(index, 1);
          } else {
            let index = this.getIndexByProp(this.filteredList, 'id', id);
            this.filteredList.splice(index, 1);
          }
          await this.alertMessage(
              'Item has been deleted',
              'info'
          );
        }
      }), 'Удаление')
    },
    async updateFilteredListByIds(data){
      this.filteredList = this.filteredList.map(function (elem) {
        if(elem.id == data.id){
          elem = Object.assign(elem, data);
        }
        return elem;
      });
    },
    async query(url) {
      await this.doWithStopRequest(() => this.doWithLoader(async () => {
        let response = await this.sendRequest(url);
        if (response) {
          await this.alertMessage(
              'Query success',
              'info'
          );
        }
      }), 'Success');
    },
    updateList() {
      this.$root.$emit('update-list')
    }
  },
  computed: {
    baseTableListToShow() {
      return this.renderListToShow(this.filteredList);
    },
    computedPerPage() {
      if(this.useCustomPagination){
        return this.paginationData.perPage;
      }
      return this.perPageDefault;
    },
  }
}
</script>

<style>
.subnav__sl {
  z-index: 1;
}
</style>
