<template>
  <div>
    <base-table
      :render-list-to-show="listToShow"
      :per-page="50"
      :wrapper-filters-data="wrapperFiltersData"
      :columns="columns"
      :table-title="'Статические страницы'"
      deleteByIdUrl="/staticPage/delete"
      deleteByIdMethod="post"
      :addModalInputData="addModalInputData"
      :update-modal-input-data="updateModalInputData"
    >
      <template v-slot:base-table-actions="props">
        <div class="topping__btns fx col-12">
          <button class="btn-t btn--borderColor flex-center" @click="props.toggleShowModalAdd">Добавить</button>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <template v-if="props.column.field === 'delete'">
          <button type="button" class="btn btn-danger" @click="props.deleteById(props.row['id'])">Удалить
          </button>
        </template>
        <template v-else-if="props.column.field === 'show_in_sitemap'">
          <button v-if="props.row['show_in_sitemap']" class="btn btn-success w-90"
                  @click="props.row['show_in_sitemap'] = 0; props.changeItemWithLoader(props.row['id'], {show_in_sitemap: false})">ДА
          </button>
          <button v-else class="btn btn-danger w-90"
                  @click="props.row['show_in_sitemap'] = 1; props.changeItemWithLoader(props.row['id'], {show_in_sitemap: true})">НЕТ
          </button>
        </template>
        <div v-else @click="toggleShowModalUpdate(props)" style="cursor: pointer">
          <template v-if="props.column.field === 'type'">
            {{
              props.formattedRow[props.column.field]['description'] + ' (' + props.formattedRow[props.column.field]['id'] + ')'
            }}
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import Modal from '../../../components/table/modal.vue';
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/table/baseTable";
import ModalUpdate from "../../../components/table/modalUpdate.vue";

export default {
  name: "categoriesList",
  components: {
    ModalUpdate,
    Multiselect,
    BaseAdminMixin,
    Modal,
    BaseTable
  },
  mixins: [
    BaseAdminMixin,
  ],
  props:[
    'user'
  ],
  data: function () {
    return {
      showModalAdd: false,
      showModalUpdate: false,
      selectedId: '',
      columns: [
        {
          label: 'URN',
          field: 'urn',
          width: '15%',
          sortable: true,
        },
        {
          label: 'SEO keys',
          field: 'seo_keys',
          width: '10%',
          sortable: false,
        },
        {
          label: 'SEO title',
          field: 'seo_title',
          width: '10%',
          sortable: false,
        },
        {
          label: 'SEO description',
          field: 'seo_description',
          sortable: false,
        },
        {
          label: 'Sitemap',
          field: 'show_in_sitemap',
          sortable: true,
        },
        {
          label: 'Удалить',
          field: 'delete',
          type: 'button',
          width: '15%',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          urn: elem['urn'],
          seo_keys: elem['seo_keys'],
          seo_title: elem['seo_title'],
          seo_description: elem['seo_description'],
          show_in_sitemap: elem['show_in_sitemap'],
        });
      }
      return data;
    },
    toggleShowModalUpdate(props) {
      let elem = props.row
      if (elem) {
        this.selectedId = elem.id
        this.updateModalInputData.items.urn.defaultValue = elem.urn
        this.updateModalInputData.items.seo_keys.defaultValue = elem.seo_keys
        this.updateModalInputData.items.seo_title.defaultValue = elem.seo_title
        this.updateModalInputData.items.seo_description.defaultValue = elem.seo_description
        this.updateModalInputData.items.show_in_sitemap.defaultValue = elem.show_in_sitemap
      }

      props.toggleShowModalUpdate(props.row);
    },
  },
  computed: {
    wrapperFiltersData() {
      return {
        title: "Статические страницы",
        filtersUrl: "/staticPage/getList",
        filtersUrlMethod: "get",
        startWithRequest: true,
      }
    },
    updateModalInputData() {
      return {
        updateModalTitle: 'Статическая страница ' + this.selectedId,
        updateModalUrl: '/staticPage/update',
        updateModalMethod: 'post',
        updateModalPrimaryKey: 'id',
        items:{
          urn: {
            simpleInputType: 'text',
            upperSpanText: 'urn',
            defaultValue: '',
            selectedKey: 'urn',
          },
          seo_keys: {
            simpleInputType: 'text',
            upperSpanText: 'SEO keys',
            defaultValue: '',
            selectedKey: 'seo_keys',
          },
          seo_title: {
            simpleInputType: 'text',
            upperSpanText: 'SEO title',
            defaultValue: '',
            selectedKey: 'seo_title',
          },
          seo_description: {
            simpleInputType: 'textarea',
            upperSpanText: 'SEO description',
            defaultValue: '',
            selectedKey: 'seo_description',
          },
          show_in_sitemap: {
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            upperSpanText: 'Показывать в сайтмапе',
            placeholder: 'disabled',
            defaultValue: '',
            bottomSpanClass: 'slider round',
            selectedKey: 'show_in_sitemap',
          },
        }
      }
    },
    addModalInputData() {
      return {
        addModalTitle: 'Добавить',
        addModalUrl: '/staticPage/create',
        addModalMethod: 'post',
        items:{
          urn: {
            simpleInputType: 'text',
            placeholder: 'urn',
            selectedKey: 'urn',
          },
          seo_keys: {
            simpleInputType: 'text',
            placeholder: 'SEO keys',
            selectedKey: 'seo_keys',
          },
          seo_title: {
            simpleInputType: 'text',
            placeholder: 'SEO title',
            selectedKey: 'seo_title',
          },
          seo_description: {
            simpleInputType: 'textarea',
            placeholder: 'SEO description',
            selectedKey: 'seo_description',
          },
          show_in_sitemap: {
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            upperSpanText: 'Показывать в сайтмапе',
            placeholder: 'disabled',
            setDefaultInFilters: false,
            bottomSpanClass: 'slider round',
            selectedKey: 'show_in_sitemap',
          },
        }
      }
    }
  }
}
</script>
